import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import { Canvas, extend, useUpdate, useFrame, useLoader, useThree, useResource } from "react-three-fiber"
import * as THREE from "three"
import { Helmet } from "react-helmet"




const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem`, marginTop:'2rem' }}>
    <Link to={props.to} >{props.children}</Link>
  </li>
)


function Background() {
  const [ref, object] = useResource()
  console.log("1")
  const cyngeo = new THREE.CylinderGeometry(5,5,5,6)
  const edges = new THREE.EdgesGeometry(cyngeo)
  console.log("2")
  const group = useRef()


  const material = new THREE.MeshBasicMaterial({
    color: 0xF3A2B0,
    wireframe: true,
  });



  useFrame(() => (group.current.rotation.y+= 0.005))
  
return (

  <group ref={group} dispose={null}>
        <mesh geometry={cyngeo} material={material}>
        </mesh>


</group>
)
}

export default function Layout({ children }) {
  return (


    <div id="big">

        <Helmet>
          <meta charSet="utf-8" />
          <title>Laboratory Umakino</title>
        </Helmet>
      <nav>
        <div id="logo">
        <Link to="/" >
        Laboratory 
        <br></br>
         Umakino       
        </Link>
        </div>

        <ul style={{ listStyle: `none`, float: `right`  }}>
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/project/">Projects</ListLink>
        </ul>  
        </nav>


      <div id="canvas">
      <Canvas>
      <Background />
      </Canvas>
      </div>

      {/* <div id="transparent"> </div> */}
      {children}
  </div>
  )
}

